import { useEffect } from "react";

export default function Disclaimer() {
  useEffect(() => {
    document.title = "Disclaimer | vpCells";
  }, []);

  return (
    <div className="px-8 py-12 my-4 sm:px-4 bg-slate-100">
      <div className="w-full px-4 lg:w-2/3 lg:mx-auto">
        <h1 className="mb-6 text-3xl font-bold leading-6 text-left uppercase tracking-tightest text-slate-700 font-heading">
          Disclaimer / Legal notice
        </h1>
        <section className="text-left border-t border-slate-300 text-slate-900">
          <p className="mt-8 text-justify">Responsible for the vpCells website:</p>

          <blockquote className="px-4 py-8 mt-8 bg-cemm-lightblue/20">
            <strong>Stefan Kubicek and the (CeMM) vpCells team</strong>
            <br />
            Team members: Jiří Reiniš, Pavel Růžička, Andreas Reicher, Maria Ciobanu, and Stefan Kubicek
            <br />
            <br />
            c/o
            <br />
            <br />
            <strong>CeMM Forschungszentrum für Molekulare Medizin GmbH</strong>
            <br />
            der Österreichischen Akademie der Wissenschaften
            <br />
            Lazarettgasse 14, AKH BT 25.3
            <br />
            1090 Wien
            <br />
            Austria
            <br />
            <br />
            <a
              href={"mailto:vpcells" + "\u0040" + "cemm.at"}
              target="_blank"
              className="underline underline-offset-4 hover:no-underline"
            >
              vpcells{"\u0040"}cemm.at
            </a>
          </blockquote>

          <hr className="mt-8 border-t border-slate-300" />

          <p className="mt-8 text-justify">
            The data provided on this website is made available for research purposes only. By accessing this website
            and utilizing the data contained herein, you acknowledge and agree to the following:
          </p>
          <p className="mt-4 text-justify">
            <strong>Limitation of Liability</strong>: The owners and operators of this website, along with any
            affiliated individuals or organizations, shall not be held liable for any damages, losses, or injuries
            arising from the use of the data provided. Users assume full responsibility for their use of the data.
          </p>
          <p className="mt-4 text-justify">
            <strong>No Warranty of Fitness for Purpose</strong>: While efforts have been made to ensure the accuracy and
            reliability of the data, no warranty is made regarding its suitability for any particular purpose. Users are
            advised to independently verify the data and exercise caution in its interpretation and application.
          </p>
          <p className="mt-4 text-justify">
            <strong>Research Use Only</strong>: The data available on this website is intended for research purposes
            only. Any commercial, legal, or other uses beyond research must obtain proper authorization and permissions
            from the respective rights holders.
          </p>
          <p className="mt-4 text-justify">
            <strong>Citation and Acknowledgment</strong>: Users are expected to adhere to academic conventions regarding
            the citation and acknowledgment of data sources. Proper attribution to the authors of the publications and
            data sources utilized should be provided in accordance with established academic norms.
          </p>
          <p className="mt-4 text-justify">
            For inquiries or further information, please contact Stefan Kubicek at{" "}
            <a
              href={"mailto:skubicek" + "\u0040" + "cemm.at"}
              target="_blank"
              className="underline underline-offset-4 hover:no-underline"
            >
              skubicek{"\u0040"}cemm.at
            </a>
            .
          </p>
          <p className="mt-4 text-justify">
            By continuing to use this website, you agree to abide by the terms and conditions outlined in this
            disclaimer.
          </p>
        </section>
      </div>
    </div>
  );
}
