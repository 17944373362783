import { useState } from "react";
import { Accordion } from "@mantine/core";
import Inforow from "./Inforow";

interface IFOVInfoboxProps {
  data: {
    experiment_ID: string;
    measurement_ID: number;
    plate_ID: number;
    row: number;
    column: number;
    FOV: number;
    image_ID: number;
    detected_cells: number;
    detected_nuclei: number;
    filtered_cells: number;
  };
}

export default function FOVInfobox({ data }: IFOVInfoboxProps) {
  const [accordionValue, setAccordionValue] = useState<string | null>(null);

  const { measurement_ID, plate_ID, row, column, FOV, image_ID, detected_cells, detected_nuclei, filtered_cells } =
    data;

  return (
    <dl className="flex flex-col mb-6 gap-y-1">
      <Accordion
        onChange={setAccordionValue}
        classNames={{
          item: "border-0",
          control: "p-0 mb-1 flex flex-row-reverse justify-between w-full hover:bg-transparent",
          label: "text-slate-700 font-medium font-sans leading-6",
          chevron: accordionValue
            ? "m-0 text-slate-900 block w-6 rotate-180 transition-transform"
            : "m-0 text-slate-900 block w-6 transition-transform",
          content: "p-0 font-sans font-medium leading-6 flex flex-col gap-y-1",
        }}
      >
        <Accordion.Item value="details">
          <Accordion.Control>{accordionValue ? "Hide details" : "Show details"}</Accordion.Control>

          <Accordion.Panel>
            <Inforow label={"Measurement ID"} content={measurement_ID} />
            <Inforow label={"Plate ID"} content={plate_ID} />
            <Inforow label={"Row"} content={row} />
            <Inforow label={"Column"} content={column} />
            <Inforow label={"FOV"} content={FOV} />
            <Inforow label={"Unique FOV ID"} content={image_ID} />
            <Inforow label={"Detected cells"} content={detected_cells} />
            <Inforow label={"Detected nuclei"} content={detected_nuclei} />
            <Inforow label={"Filtered cells"} content={filtered_cells} />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </dl>
  );
}
