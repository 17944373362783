import { useEffect } from "react";
import CloneExamples from "../assets/clone_examples.jpg";
import NicePool from "../assets/nice_pool.jpg";
import IdeaFigure from "../assets/idea_figure.png";
import ChannelsBreakdown from "../assets/channels_breakdown.png";

export default function About() {
  useEffect(() => {
    document.title = "About | vpCells";
  }, []);

  return (
    <div className="px-8 py-12 my-4 sm:px-4 bg-slate-100">
      <div className="w-full px-4 lg:w-2/3 lg:mx-auto">
        <h1 className="mb-6 text-3xl font-bold leading-6 text-left uppercase tracking-tightest text-slate-700 font-heading">
          About vpCells
        </h1>
        <section className="text-left border-t border-slate-300 text-slate-900">
          <p className="mt-8 text-justify">
            This interactive web atlas of intron-tagged proteins is a companion to the publication{" "}
            <strong>Pooled multicolor tagging for visualizing subcellular protein dynamics</strong>{" "}
            <a
              href={"https://www.nature.com/articles/s41556-024-01407-w"}
              target="_blank"
              className="underline underline-offset-4 hover:no-underline"
            >
              [Reicher*, Reiniš* et al]
            </a>
            .
          </p>

          <p className="mt-4 text-justify">
            We present a collection of fluorescence microscopy images of 1,158 tagged proteins in 4,576 isolated clonal
            cell lines (HAP1, HEK293T cells). It has been established as a part of our Visual Proteomics approach,
            described in more detail below and in full detail in the publication. Each protein of interest in this
            collection is endogenously tagged with either EGFP (frame 0 indicated in sgRNA details) or mScarlet (frame
            1). Frozen clones are available upon request – in case you are interested in a particular protein, please
            reach out to{" "}
            <a
              href={"mailto:vpcells" + "\u0040" + "cemm.at"}
              target="_blank"
              className="underline underline-offset-4 hover:no-underline"
            >
              vpcells{"\u0040"}cemm.at
            </a>
            . Please be aware that besides the protein of interest EGFP or mScarlet, other fluorophores are present in
            each clone as described below. We decided to include the entire dataset of over 75,000 images and not remove
            clones where the tagged proteins do not match the expected localization or where there is a possible mix of
            clones in the well.
          </p>

          <h2 className="mt-8 mb-4 text-xl uppercase font-heading tracking-tightested text-slate-700">
            Visual Proteomics
          </h2>
          <p className="text-justify">
            The subcellular localizations of proteins can be highly dynamic and change during many cellular processes or
            in response to different perturbations. Proteins can shuttle between compartments, localize to membrane-less
            organelles, bind to subcellular structures or form complexes. In different compartments or different
            environments, the same protein can carry out different functions. Therefore, knowing the subcellular
            localizations of proteins is essential for a complete understanding of cellular biology.
          </p>

          <div className="mt-4 text-justify">
            Studying this spatial organization of the proteome can be done using various microscopy-based methods.
            Current methods are usually performed in an arrayed format to study one labeled protein at a time. As such,
            these are not easily scalable to study changes in response to many perturbations. In contrast, in our Visual
            Proteomics approach, we want to use large cell pools expressing hundreds of tagged proteins to
            simultaneously monitor changes in protein abundance and localization in a live cell imaging time course. A
            key requirement for this is to be able to determine the identity of the different tagged proteins in cells
            in the pool by computational image analysis alone. Our implementation of this strategy is to have two tagged
            proteins (EGFP, mScarlet) and a BFP-based visual barcode in every cell. The unique combination of
            localization and intensity patterns can be exploited to recognize individual clones and the identity of the
            tagged proteins. We termed our multiple-tagged cells Visual Proteomics Cells, or vpCells in short.
          </div>

          <div className="w-full mt-8">
            <img src={CloneExamples} alt="Examples of vpCells clones." className="w-full" />
          </div>
          <p className="mt-1 mr-1 text-sm italic text-right">Examples of vpCells clones.</p>

          <div className="w-full mt-8">
            <img src={NicePool} alt="A vpCells pool (HAP1 cells)." className="w-full" />
          </div>
          <p className="mt-1 mr-1 text-sm italic text-right">A vpCells pool (HAP1 cells).</p>

          <h2 className="mt-8 mb-4 text-xl uppercase font-heading tracking-tightested text-slate-700">
            Generation of vpCells and the clone collection
          </h2>

          <div className="w-full mt-8">
            <img src={IdeaFigure} alt="A vpCells pool (HAP1 cells)." className="w-full" />
          </div>
          <p className="mt-1 mr-1 text-sm italic text-right">An overview of our Visual Proteomics approach.</p>

          <p className="mt-8 text-justify">
            To generate vpCells, we use a CRISPR-Cas9-based intron tagging strategy to generate cell pools expressing
            hundreds of endogenously tagged proteins. In brief, cells are transduced with an intron-targeting sgRNA
            library and a synthetic exon containing the coding sequence of EGFP is integrated at target sites.
            Fluorescent cells are sorted to obtain a cell pool, where a different protein is tagged in every cell,
            depending on the intron-targeting sgRNA expressed by that cell. This process is repeated and in every cell,
            a second protein is tagged with mScarlet in a second round of intron tagging. Then, the fluorescent protein
            mAmetrine localized to the plasma membrane and a far red fluorescent protein localizing to the nucleus are
            added. These additional channels are required in image analysis for segmentation and to further increase the
            complexity required to discriminate more clones in the pool.
          </p>

          <div className="w-full mt-8">
            <img src={ChannelsBreakdown} alt="Overview of fluorophores in vpCells clones." className="w-full" />
          </div>
          <p className="mt-1 mr-1 text-sm italic text-right">Overview of fluorophores in vpCells clones.</p>

          <p className="mt-8 text-justify">
            Finally, thousands of individual clones are isolated from the pools by single cell dilution. These isolated
            clonal cell lines are genotyped and imaged to train a computational model that is capable of recognizing the
            clones in the pool. With that computational model, vpCells can finally be used for monitoring changes in the
            subcellular localization and abundance of hundreds of proteins in a pooled format.
          </p>

          <p className="mt-4 text-justify">
            The detailed descriptions of sgRNA libraries we designed and used are available in CSV/Excel format in{" "}
            <a href="/downloads" className="underline underline-offset-4 hover:no-underline">
              the Downloads section
            </a>
            .
          </p>
        </section>
      </div>
    </div>
  );
}
