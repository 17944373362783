import { useState } from "react";
import { classNames } from "../../util/util";

export default function GalleryImage({ src }: { src: string }) {
  const [loaded, setLoaded] = useState(false);

  return (
    <div>
      {loaded ? null : <div className="w-full aspect-square bg-slate-300" />}
      <img className={classNames(loaded ? "w-full h-full" : "hidden")} src={src} onLoad={() => setLoaded(true)} />
    </div>
  );
}
