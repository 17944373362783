import {
  PromiseTypeReturnTypeTypeofgeneServiceGetGeneDetail as Gene,
  PromiseTypeReturnTypeTypeofgeneServiceGetWells as Wells,
} from "../services/api";
import { constructImageObj } from "./util";

export const getSgRNAs = (gene: Gene) => {
  return gene.transcripts.flatMap(({ ENSEMBL_gene_ID, ENSEMBL_transcript_ID, sgRNAs }) =>
    sgRNAs.map((sgRNA) => ({
      ...sgRNA,
      ENSEMBL_transcript_ID,
      ENSEMBL_gene_ID,
      thumbnail: (() => {
        const { well } = sgRNA.wells[0];
        const { sgRNA_ID, frame } = sgRNA;
        // @ts-ignore
        const { experiment_ID, plate_ID, row, column, clone_ID } = well;
        const { measurement_ID, FOV, image_ID, rating, score, detected_cells, detected_nuclei, filtered_cells } =
          well.images[0];

        return constructImageObj(
          {
            sgRNA_ID,
            experiment_ID,
            plate_ID,
            measurement_ID,
            clone_ID,
            row,
            column,
            FOV,
            frame,
            rating,
            score,
            detected_cells,
            detected_nuclei,
            filtered_cells,
          },
          image_ID
        );
      })(),
    }))
  );
};

export const getWellObjects = (wells?: Wells | null) => {
  if (!wells || !wells.transcripts.length) {
    return [];
  }

  // for genes with multiple transcripts, api call getWells for a transcript returns
  // the other transcripts as well with empty sgRNAs relation. therefore we need to
  // find the correct transcript for our requested well
  const transcript = wells.transcripts.find((transcript) => transcript.sgRNAs.length);

  if (!transcript) {
    return [];
  }

  const wellObjects = transcript.sgRNAs[0].wells.map(({ frame, well: { images, ...well } }) => ({
    // @ts-ignore
    FOVs: images.map(({ image_ID, ...image }) => constructImageObj({ ...well, ...image, frame }, image_ID)),
    frame,
    ...well,
  }));

  return wellObjects;
};
