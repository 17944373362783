import React from "react";
import { classNames } from "../../util/util";
import GallerySelect from "./Select";

interface IGalleryAlphabetProps {
  filter: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
}

export default function GalleryAlphabet({ filter, setFilter }: IGalleryAlphabetProps) {
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

  return (
    <div className="w-full">
      <div className="max-w-32 lg:hidden">
        <GallerySelect options={alphabet} selected={filter} setSelected={setFilter} />
      </div>
      <div className="hidden lg:block">
        <div className="flex px-3 space-x-1 overflow-x-auto">
          {alphabet.map((letter) => (
            <button
              key={letter}
              type="button"
              title={letter === filter ? `Filtering for ${letter}` : `Filter for ${letter}`}
              className={classNames(
                letter === filter
                  ? "bg-cemm-blue hover:bg-cemm-blue/90 border-cemm-blue hover:border-cemm-blue/90 text-white"
                  : "bg-neutral-100 text-slate-700 border-slate-300",
                "flex items-center justify-center h-[2.375rem] w-[2.375rem] border-2 font-medium leading-5  uppercase transition-opacity font-heading text-lg"
              )}
              onClick={() => {
                if (letter !== filter) {
                  setFilter(letter);
                }
              }}
            >
              {letter}
            </button>
          ))}
          {/* <button
            type="button"
            title="Show all"
            className={classNames(
              filter === "all"
                ? "bg-cemm-blue hover:bg-cemm-blue/90 border-cemm-blue hover:border-cemm-blue/90 text-white"
                : "bg-neutral-100 text-slate-700 border-slate-300",
              "flex items-center justify-center py-[9px] border-2 px-3 font-medium leading-5  uppercase transition-opacity font-heading text-lg"
            )}
            onClick={() => {
              setFilter("all");
              setIsLoading(true);
            }}
          >
            All
          </button> */}
        </div>
      </div>
    </div>
  );
}
