import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useApi } from "../../App";

import GalleryGrid from "./Grid";
import GalleryAlphabet from "./Alphabet";
import Loading from "../../components/Loading";

export default function Gallery() {
  useEffect(() => {
    document.title = "Gallery | vpCells";
  }, []);

  const api = useApi();
  const { data: genes } = useQuery("genes", api.genes.getGenes, {
    select: (x) => x.data.genes,
  });

  const [letterFilter, setLetterFilter] = useState<string>("A");

  if (genes === undefined) {
    return <Loading />;
  }

  return (
    <div className="px-8 py-12 my-4 sm:px-4 bg-slate-100">
      <div className="flex flex-col space-y-4 divide-y divide-slate-300">
        <GalleryAlphabet filter={letterFilter} setFilter={setLetterFilter} />

        <GalleryGrid entries={genes} filter={letterFilter} />
      </div>
    </div>
  );
}
