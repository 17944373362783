import { SetStateAction, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import type { GeneHeaders } from "../../services/api";

import { classNames, thumbnailToURL } from "../../util/util";
import GalleryImage from "./Image";

interface IGalleryGridProps {
  entries: GeneHeaders;
  filter: string;
}

export default function GalleryGrid({ entries, filter }: IGalleryGridProps) {
  const filteredEntries = useMemo(() => {
    if (filter === "all") {
      return entries;
    }

    return entries.filter((gene) => gene.gene.startsWith(filter));
  }, [entries, filter]);

  return (
    <div className="grid grid-cols-1 sm:pt-8 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-8">
      {filteredEntries.map(({ gene, description }) => (
        <Link key={gene} to={`/protein/${gene}`} className="px-3 pt-3 pb-5 text-left hover:bg-cemm-lightblue/25">
          <GalleryImage src={thumbnailToURL(`/thumbnails/proteins/${gene}.jpg`)} />

          <h3 className="mt-2 text-lg font-medium font-heading text-cemm-blue">{gene}</h3>
          <p className="font-sans text-sm text-slate-900">{description}</p>
        </Link>
      ))}
    </div>
  );
}
