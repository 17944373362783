import { useState, useEffect } from "react";
import { useApi } from "../App";
import { useQuery } from "react-query";
import { useLocation, Link } from "react-router-dom";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { classNames } from "../util/util";

import { Searchbox } from "./Searchbox";
import NavbarLink from "./NavbarLink";
import VPCells from "../assets/vpcells-logo.svg";

export function Header() {
  const api = useApi();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => setMenuOpen(false), [location]);

  const { data: genes, isLoading } = useQuery("genes", api.genes.getGenes, {
    select: (x) => x.data.genes,
  });

  const searchboxEnabled = location.pathname.startsWith("/gallery") || location.pathname.startsWith("/protein");
  const searchboxShown = !isLoading && genes !== undefined && searchboxEnabled;

  return (
    <div className="flex-none mt-2 sm:px-4 bg-slate-100">
      <nav className="flex flex-wrap">
        <div className="flex items-center justify-between w-full px-8 mr-auto space-x-4 border-transparent border-l-3 sm:border-0 sm:px-0 sm:w-auto">
          <Link to="/" className="flex flex-col items-start pt-4 pb-2 sm:flex-row font-heading">
            <img src={VPCells} className="h-12 sm:h-16" />
          </Link>

          <div className="flex items-center py-4">
            <button
              className="inline-flex items-center justify-center p-2 rounded-md sm:hidden text-slate-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cemm-blue2"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <span className="sr-only">Open main menu</span>
              {menuOpen ? (
                <XIcon className="block w-6 h-6" aria-hidden="true" />
              ) : (
                <MenuIcon className="block w-6 h-6" aria-hidden="true" />
              )}
            </button>
          </div>

          {searchboxShown && (
            <div className="hidden lg:block w-80">
              <Searchbox genes={genes} />
            </div>
          )}
        </div>

        <div
          className={classNames(
            menuOpen ? "flex sm:flex" : "hidden sm:flex",
            "uppercase font-heading border-t border-slate-200 sm:border-0 flex-col w-full sm:w-auto sm:flex-row gap-x-3 mb-4 sm:mb-0"
          )}
        >
          <NavbarLink target="Gallery" />
          <NavbarLink target="About" />
          <NavbarLink target="Downloads" />
          <NavbarLink target="Contact" />
        </div>
      </nav>
    </div>
  );
}
