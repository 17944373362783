import { useEffect } from "react";

export default function PrivacyPolicy() {
  useEffect(() => {
    document.title = "Privacy Policy | vpCells";
  }, []);

  return (
    <div className="px-8 py-12 my-4 sm:px-4 bg-slate-100">
      <div className="w-full px-4 lg:w-2/3 lg:mx-auto">
        <h1 className="mb-6 text-3xl font-bold leading-6 text-left uppercase tracking-tightest text-slate-700 font-heading">
          Privacy policy
        </h1>
        <section className="text-left border-t border-slate-300 text-slate-900">
          <p className="mt-8 text-justify">
            We want to process as little personal information as possible when you use the atlas of proteins. That is
            why we have chosen Fathom Analytics for our website analytics, which does not use cookies and complies with
            the GDPR, ePrivacy (including PECR), COPPA and CCPA. Using this privacy-friendly website analytics software,
            your IP address is only briefly processed, and we have no way of identifying you. As per the CCPA, your
            personal information is de-identified. You can read more about this on{" "}
            <a
              className="underline underline-offset-4 hover:no-underline"
              href="https://usefathom.com/"
              target="_blank"
            >
              Fathom Analytics
            </a>
            ' website.
          </p>

          <p className="mt-4 text-justify">
            The purpose of us using this software is to understand our website traffic in the most privacy-friendly way
            possible so that we can continually improve the atlas of proteins. The lawful basis as per the GDPR is
            "Article 6(1)(f); where our legitimate interests are to improve our website and business continually." As
            per the explanation, no personal data is stored over time.
          </p>
        </section>
      </div>
    </div>
  );
}
