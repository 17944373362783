import { useApi } from "../../../App";
import { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { notifications } from "@mantine/notifications";
import { Button } from "@mantine/core";
import Loading from "../../../components/Loading";
import GeneInfobox from "./Gene";
import GuideInfobox from "./Guide";
import FOVInfobox from "./FOV";
import Inforow from "./Inforow";
import Score from "../Rating";

import { useCookies } from "react-cookie";

import type { sgRNAs, wellObjects } from "../../../util/types";
import Feedback from "../Feedback";
import ThawingModal from "../ThawingModal";

interface IPanelProps {
  geneID: string;
  geneName: string;
  sgRNAs: sgRNAs;
  sgRNAIndex: number;
  wellDetail?: wellObjects[0];
  FOVIndex: number;
}

export default function Panel({ geneID, geneName, sgRNAs, sgRNAIndex, wellDetail, FOVIndex }: IPanelProps) {
  const [cookies, setCookie] = useCookies(["thaw_clones"]);
  const [thawingModalOpen, setThawingModalOpen] = useState(false);

  const geneData = { ENSEMBL_gene_ID: sgRNAs[0].ENSEMBL_gene_ID, gene_ID: geneID };
  const guideData = { ...sgRNAs[sgRNAIndex] };
  const FOVData = !wellDetail
    ? null
    : { ...wellDetail.FOVs[FOVIndex].info, image_ID: wellDetail.FOVs[FOVIndex].image_ID };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "F10") {
        if (FOVData === null) {
          return;
        }

        // clone already enqueued
        // if (cookies.thaw_clones?.includes("," + FOVData.clone_ID + ",")) {}

        setCookie("thaw_clones", (cookies.thaw_clones || ",") + FOVData.clone_ID + ",", {
          path: "/",
          domain: ".vpcells.cemm.at",
          sameSite: "none",
        });

        setThawingModalOpen(true);
      }

      if (event.key === "F9") {
        console.log(cookies.thaw_clones);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [FOVData]);

  return (
    <>
      <ThawingModal open={thawingModalOpen} setOpen={setThawingModalOpen} />
      <div className="sticky top-6">
        <h1 className="text-3xl font-bold text-cemm-blue font-heading">{geneID}</h1>
        <div className="mb-3 text-lg font-semibold text-slate-700">{geneName}</div>

        <GeneInfobox data={geneData} />
        <GuideInfobox data={guideData} />

        {FOVData === null ? (
          <Loading />
        ) : (
          <div className="flex flex-col gap-y-1">
            <Inforow label={"Experiment ID"} content={FOVData.experiment_ID} />
            <Inforow label={"Clone ID"} content={FOVData.clone_ID} />
            <FOVInfobox data={FOVData} />
          </div>
        )}

        {/* {!scores.length || FOVData === null ? (
        <Loading />
      ) : (
        <Score upvote={upvote} downvote={downvote} imageID={FOVData.image_ID} displayedValue={scores[FOVIndex]} />
      )}

      {FOVData === null ? (
        <Loading />
      ) : (
        <Feedback imageID={FOVData.image_ID} sgRNAID={guideData.sgRNA_ID} frame={FOVData.frame} />
      )} */}
      </div>
    </>
  );
}
