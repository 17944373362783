import { classNames } from "../../util/util";
import { ArrowRightIcon } from "@heroicons/react/solid";
import type { sgRNAs } from "../../util/types";

interface IGuideOverviewProps {
  sgRNAs: sgRNAs;
  onSelect: (index: number) => void;
  guideIndex: number;
}

export default function GuideOverview({ sgRNAs, onSelect, guideIndex }: IGuideOverviewProps) {
  return (
    <>
      <div className="mb-2 text-lg font-semibold text-left text-slate-900">sgRNA overview</div>

      {sgRNAs.map(({ intron }, index) => (
        <div
          key={intron}
          className={classNames(
            guideIndex === index ? "bg-cemm-blue" : "bg-cemm-lightblue/25",
            "flex justify-between w-full px-4 py-1.5 text-sm cursor-pointer group"
          )}
          onClick={() => onSelect(index)}
        >
          <span
            className={classNames(guideIndex === index ? "text-white" : "text-slate-900", "font-semibold")}
          >{`sgRNA ${index + 1} (intron ${intron})`}</span>
          <div
            className={classNames(
              guideIndex === index ? "text-white" : "text-slate-600 underline",
              "flex items-center font-semibold group-hover:no-underline underline-offset-2"
            )}
          >
            {guideIndex === index ? "Shown" : "View"}
            <ArrowRightIcon className="w-2.5 h-2.5 ml-1" />
          </div>
        </div>
      ))}
    </>
  );
}
