import { classNames } from "../../../util/util";

import { Ref, useMemo } from "react";
import type { sgRNAs } from "../../../util/types";

interface IGuideScrollbarProps {
  sgRNAs: sgRNAs;
  onSelect: (index: number) => void;
  guideIndex: number;
  reference: Ref<HTMLDivElement>;
}

export default function GuideScrollbar({ sgRNAs, onSelect, guideIndex, reference }: IGuideScrollbarProps) {
  const orderedSgRNAs = useMemo(() => {
    return sgRNAs.sort((a, b) => b.wells[0].well.images[0].score - a.wells[0].well.images[0].score);
  }, [sgRNAs]);

  return (
    <>
      <div className="mb-2 text-lg font-semibold text-left text-slate-900">sgRNAs for this gene</div>

      <div className="flex flex-col w-full mb-6 ">
        <div ref={reference} className="flex p-1 space-x-1 overflow-x-auto scroll-smooth bg-cemm-lightblue/25">
          {orderedSgRNAs.map(({ thumbnail }, index) => (
            <div
              className={classNames(
                guideIndex === index
                  ? "ring-4 ring-cemm-blue"
                  : "focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-slate-100 focus-within:ring-cemm-blue hover:opacity-75 transition-opacity cursor-pointer",
                "flex-none"
              )}
              key={index}
              onClick={() => onSelect(index)}
            >
              <img alt="" className={`object-cover pointer-events-none h-36 w-36`} src={thumbnail.URL(true)} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
