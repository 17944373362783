import React, { SetStateAction } from "react";
import { ImageObject } from "../../util/types";

import { classNames } from "../../util/util";

interface IFOVGridProps {
  FOVs: ImageObject[];
  FOVIndex: number;
  onSelect: (image_ID: number, index: number) => void;
  setGridShown: React.Dispatch<SetStateAction<boolean>>;
}

export default function FOVGrid({ FOVs, FOVIndex, onSelect, setGridShown }: IFOVGridProps) {
  return (
    <>
      <div className="grid justify-center min-h-full grid-cols-4 gap-1 p-4 2xl:grid-cols-5 bg-cemm-lightblue/25">
        {FOVs.map(({ URL, image_ID }, index) => (
          <div
            className={classNames(
              FOVIndex === index
                ? "ring-4 ring-cemm-blue"
                : "focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-slate-100 focus-within:ring-cemm-blue hover:opacity-75 transition-opacity",
              "flex-none cursor-pointer"
            )}
            key={image_ID}
            onClick={() => {
              onSelect(image_ID, index);
              setGridShown(false);
            }}
          >
            <img alt="" className="object-cover w-full pointer-events-none bg-cemm-lightblue/25" src={URL()} />
          </div>
        ))}
      </div>
    </>
  );
}
