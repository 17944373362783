import { useEffect } from "react";

export default function Downloads() {
  useEffect(() => {
    document.title = "Downloads | vpCells";
  }, []);

  return (
    <div className="px-8 py-12 my-4 sm:px-4 bg-slate-100">
      <div className="w-full px-4 lg:w-2/3 lg:mx-auto">
        <h1 className="mb-6 text-3xl font-bold leading-6 text-left uppercase tracking-tightest text-slate-700 font-heading">
          Downloads
        </h1>
        <section className="text-left border-t border-slate-300 text-slate-900">
          <p className="mt-8 text-justify">
            Intron targeting sgRNAs were designed as described in{" "}
            <strong>Pooled multicolor tagging for visualizing subcellular protein dynamics</strong>{" "}
            <a
              href={"https://www.nature.com/articles/s41556-024-01407-w"}
              target="_blank"
              className="underline underline-offset-4 hover:no-underline"
            >
              [Reicher*, Reiniš* et al]
            </a>
            . In brief, Ensembl BioMart was used for selecting transcripts with a Consensus Coding Sequence ID (CCDS ID)
            of human genes and to define intronic regions. The GuideScan tool was used for selecting up to 20 sgRNAs per
            intron, which were ranked based on a combined on- and off-target score. A subset of top-ranked sgRNAs were
            selected from this database to generate two genome-wide libraries targeting intron frames 0 and 1. The list
            of sgRNAs in these two libraries and the database containing up to 20 sgRNAs per intron can be can be
            downloaded below.
          </p>

          <h2 className="mt-8 mb-4 text-xl uppercase font-heading tracking-tightested text-slate-700">
            Human Genome-wide Intron Tagging Library, Frame 0
          </h2>

          <p className="mt-4">
            <span>
              90,657 sgRNAs targeting 73,817 introns of 14,158 genes (available on{" "}
              <a
                href={"https://www.addgene.org/pooled-library/kubicek-human-intron-tagging/"}
                target="_blank"
                className="underline underline-offset-4 hover:no-underline"
              >
                Addgene
              </a>
              )
            </span>
            <br />
            <a
              href="https://introntaggingstorage.blob.core.windows.net/webtoolexports-v2/downloads/sgRNAs_genomewide_frame0.csv"
              target="_blank"
              className="not-italic font-bold underline underline-offset-2 hover:no-underline text-cemm-blue"
            >
              sgRNAs_genomewide_frame0.csv
            </a>
          </p>

          <h2 className="mt-8 mb-4 text-xl uppercase font-heading tracking-tightested text-slate-700">
            Human Genome-wide Intron Tagging Library, Frame 1
          </h2>

          <p className="mt-4">
            <span>
              72,580 sgRNAs targeting 51,939 introns of 14,011 genes (available on{" "}
              <a
                href={"https://www.addgene.org/pooled-library/kubicek-human-intron-tagging/"}
                target="_blank"
                className="underline underline-offset-4 hover:no-underline"
              >
                Addgene
              </a>
              )
            </span>
            <br />
            <a
              href="https://introntaggingstorage.blob.core.windows.net/webtoolexports-v2/downloads/sgRNAs_genomewide_frame1.csv"
              target="_blank"
              className="not-italic font-bold underline underline-offset-2 hover:no-underline text-cemm-blue"
            >
              sgRNAs_genomewide_frame1.csv
            </a>
          </p>

          <h2 className="mt-8 mb-4 text-xl uppercase font-heading tracking-tightested text-slate-700">
            Human intron-targeting sgRNA database
          </h2>

          <p className="mt-4">
            <span>up to 20 sgRNAs per intron in the human genome (approximately 3 million sgRNAs)</span>
            <br />
            <a
              href="https://introntaggingstorage.blob.core.windows.net/webtoolexports-v2/downloads/Human_IntronTargeting_sgRNA_Database_v1_1.csv"
              target="_blank"
              className="not-italic font-bold underline underline-offset-2 hover:no-underline text-cemm-blue"
            >
              Human_IntronTargeting_sgRNA_Database_v1_1.csv
            </a>
          </p>
        </section>
      </div>
    </div>
  );
}
