import * as Fathom from "fathom-client";
import { createContext, useContext, useEffect, useMemo } from "react";
import { RecoilRoot } from "recoil";
import { QueryClient, QueryClientProvider } from "react-query";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { Api } from "./services/api";
import { Outlet, useLocation } from "react-router-dom";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { CookiesProvider } from "react-cookie";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 3600000,
    },
  },
});
const apiClientContext = createContext<Api<string>>(null!);

export function useApi() {
  return useContext(apiClientContext);
}

export function App() {
  const location = useLocation();
  const client = useMemo(
    () =>
      new Api<string>({
        baseUrl: import.meta.env.VITE_API_URL,
      }),
    []
  );

  useEffect(() => {
    Fathom.load("EGDJDRBD", {
      url: "https://holiday-seventeen.cemm.at/script.js",
      includedDomains: ["vpcells.cemm.at"],
    });
  }, []);

  useEffect(() => {
    Fathom.trackPageview();
  }, [location]);

  return (
    <CookiesProvider>
      <MantineProvider>
        <RecoilRoot>
          <QueryClientProvider client={queryClient}>
            <apiClientContext.Provider value={client}>
              <Notifications />
              <div className="flex flex-col w-full min-h-screen bg-slate-200">
                <div className="flex flex-col flex-1 container max-w-[108rem] min-h-full mx-auto sm:px-4 sm:py-2">
                  <Header />
                  <Outlet />
                  <Footer />
                </div>
              </div>
            </apiClientContext.Provider>
          </QueryClientProvider>
        </RecoilRoot>
      </MantineProvider>
    </CookiesProvider>
  );
}
