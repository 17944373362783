import { classNames } from "../../../util/util";

import { Ref, useMemo } from "react";
import type { wellObjects } from "../../../util/types";
import Loading from "../../../components/Loading";

interface IWellScrollbarProps {
  wellObjects: wellObjects;
  onSelect: (index: number) => void;
  selectedWell?: number;
  wellIndex: number;
  reference: Ref<HTMLDivElement>;
  isLoading: boolean;
}

export default function WellScrollbar({
  wellObjects,
  onSelect,
  selectedWell,
  wellIndex,
  reference,
  isLoading,
}: IWellScrollbarProps) {
  const orderedWellObjects = useMemo(() => {
    return wellObjects.sort((a, b) => b.FOVs[0].info.score - a.FOVs[0].info.score);
  }, [wellObjects]);

  if (isLoading || selectedWell === undefined) {
    return (
      <>
        <div className="mb-2 text-lg font-semibold text-left text-slate-900">Clones for selected sgRNA</div>
        <div className="flex flex-col w-full mb-6">
          <Loading />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="mb-2 text-lg font-semibold text-left text-slate-900">
        Clones for selected sgRNA
        {` (${wellIndex + 1}/${wellObjects.length})`}
      </div>
      <div className="flex flex-col w-full mb-6">
        <div ref={reference} className="flex p-1 space-x-1 overflow-x-auto scroll-smooth bg-cemm-lightblue/25">
          {orderedWellObjects.map(({ FOVs, well_ID }) => (
            <div
              className={classNames(
                selectedWell === well_ID
                  ? "ring-4 ring-cemm-blue"
                  : "focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-slate-100 focus-within:ring-cemm-blue hover:opacity-75 transition-opacity cursor-pointer",
                "flex-none"
              )}
              key={well_ID}
              onClick={() => onSelect(well_ID)}
            >
              <img alt="" className={`object-cover pointer-events-none h-36 w-36`} src={FOVs[0].URL(true)} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
