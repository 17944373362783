import { classNames } from "../util/util";

interface ILoadingProps {
  backgroundClass?: string;
  margin?: number;
}

export default function Loading({ backgroundClass, margin }: ILoadingProps) {
  return (
    <div
      className={classNames(
        backgroundClass || "bg-slate-100",
        margin !== undefined ? `my-${margin}` : "my-4",
        "flex justify-center px-8 py-12 sm:px-4"
      )}
    >
      <svg className="w-10 h-10 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="#00AECC" strokeWidth="4"></circle>
        <path
          className="opacity-75"
          fill="#00AECC"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
  );
}
