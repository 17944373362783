import { Fragment, useEffect, useState, useMemo } from "react";
import { matchSorter } from "match-sorter";
import { useNavigate } from "react-router-dom";
import { useApi } from "../App";
import useDebounce from "../util/useDebounce";
import { Transition, Combobox } from "@headlessui/react";
import { GeneHeaders } from "../services/api";
import { SearchIcon } from "@heroicons/react/solid";

import { classNames } from "../util/util";

interface ISearchboxProps {
  genes: GeneHeaders;
  isOnMainPage?: boolean;
}

export function Searchbox({ genes, isOnMainPage }: ISearchboxProps) {
  const api = useApi();
  const navigate = useNavigate();
  const [selectedGene, setSelectedGene] = useState<GeneHeaders[0]>();
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (selectedGene !== undefined) {
      navigate(`/protein/${selectedGene.gene}`);
    }
  }, [selectedGene]);

  const filterQuery = useDebounce(query, 150);
  const statsQuery = useDebounce(query, 500);

  const filteredGenes = useMemo(
    () =>
      matchSorter(genes, filterQuery, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["gene", "description", "ENSEMBL_gene_ID"],
      }).slice(0, 20),
    [filterQuery]
  );

  useEffect(() => {
    if (statsQuery.length >= 3) {
      api.stats.recordSearchTerm({ searchTerm: statsQuery });
    }
  }, [statsQuery]);

  return (
    <Combobox value={selectedGene} onChange={setSelectedGene}>
      <div className={classNames(isOnMainPage ? "mt-1 xl:mt-0" : "", "relative")}>
        <div
          className={classNames(
            isOnMainPage ? "border-slate-700 text-slate-700" : "text-slate-900",
            "relative w-full overflow-hidden font-medium text-left bg-white border-2 focus:border-cemm-blue focus:ring-offset-cemm-blue focus:outline-none font-heading"
          )}
        >
          <Combobox.Input
            className={classNames(
              isOnMainPage ? "text-slate-900 py-3" : "text-slate-700 py-2",
              "w-full pl-3 pr-10 leading-5 uppercase border-none placeholder:uppercase focus:ring-0"
            )}
            placeholder="Search for a protein..."
            onChange={(event) => setQuery(event.target.value)}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
            <SearchIcon className="w-5 h-5 text-slate-700" aria-hidden="true" />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery("")}
        >
          <Combobox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-80 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredGenes.length === 0 && query !== "" ? (
              <div className="relative px-4 py-2 text-left cursor-default select-none text-slate-700">
                No results found.
              </div>
            ) : (
              filteredGenes.map((gene) => (
                <Combobox.Option
                  key={gene.ENSEMBL_gene_ID}
                  className={({ active }) =>
                    `relative text-left cursor-default select-none py-2 px-4 ${
                      active ? "bg-cemm-blue text-white" : "text-slate-900"
                    }`
                  }
                  value={gene}
                >
                  {() => (
                    <>
                      <div className="flex items-baseline space-x-1">
                        <div className="text-lg font-bold truncate">{gene.gene}</div>
                        <div className="text-sm opacity-75">{gene.ENSEMBL_gene_ID}</div>
                      </div>
                      <div className="text-sm">{gene.description}</div>
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
}
