import { useState, useEffect, useRef, useMemo } from "react";
import { classNames } from "../../util/util";

import Loading from "../../components/Loading";
import FOVControls from "./Controls/FOVs";
import FOVGrid from "./FOVGrid";
import ChannelControls from "./Controls/Channels";

import type { ImageObject } from "../../util/types";
import useWindowSize from "../../util/useWindowSize";

interface IImageDetailProps {
  FOVs?: ImageObject[];
  FOVIndex: number;
  onSelect: (image_ID: number, index: number) => void;
  onIncrement: (direction: -1 | 1) => void;
  gridShown: boolean;
  setGridShown: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ImageDetail({
  FOVs,
  FOVIndex,
  onSelect,
  onIncrement,
  gridShown,
  setGridShown,
}: IImageDetailProps) {
  const [channels, setChannels] = useState("011");
  const imageRef = useRef<HTMLImageElement>(null);
  const { width } = useWindowSize();

  const scaleWidth = useMemo(
    () => Math.floor((imageRef.current?.clientWidth || 0) / 10.2492),
    [imageRef.current, width]
  );

  if (FOVs === undefined) {
    return <Loading />;
  }

  return (
    <>
      {!gridShown && (
        <FOVControls
          gridShown={gridShown}
          setGridShown={setGridShown}
          FOVIndex={FOVIndex}
          onIncrement={onIncrement}
          imageCount={FOVs.length}
        />
      )}

      <div className={classNames(!gridShown && "aspect-square", "w-full bg-cemm-lightblue/25")}>
        {gridShown ? (
          <FOVGrid FOVs={FOVs} FOVIndex={FOVIndex} onSelect={onSelect} setGridShown={setGridShown} />
        ) : (
          <div className="relative w-full h-full">
            <img className="w-full h-full" src={FOVs[FOVIndex].URL(false, channels)} ref={imageRef} />
            <div className="absolute flex flex-col items-start text-white bottom-6 left-6">
              <span className="text-[16px] font-bold select-none">20µm</span>
              <div className="bg-white" style={{ width: scaleWidth, height: 8 }} />
            </div>
          </div>
        )}
      </div>

      {!gridShown && <ChannelControls setChannels={setChannels} />}
    </>
  );
}
