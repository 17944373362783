import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/solid";

interface IGallerySelectProps {
  options: string[];
  selected: string;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
}

export default function GallerySelect({ options, selected, setSelected }: IGallerySelectProps) {
  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }: { open: boolean }) => (
        <>
          <Listbox.Label className="block text-base leading-6 text-left uppercase text-slate-700 font-heading">
            Filter for...
          </Listbox.Label>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full py-3 pl-3 pr-10 overflow-hidden font-medium leading-5 text-left uppercase bg-white border-2 text-slate-700 focus:ring-0 border-slate-700 focus:border-cemm-blue focus:ring-offset-cemm-blue focus:outline-none font-heading">
              <span className="block truncate">{selected}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon className="w-5 h-5 text-slate-700" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options.map((option) => (
                  <Listbox.Option
                    key={option}
                    className="relative px-4 py-2 text-lg font-bold text-left uppercase truncate cursor-pointer select-none text-slate-900 hover:bg-cemm-blue hover:text-white"
                    value={option}
                  >
                    {option}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
