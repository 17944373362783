import { Link } from "react-router-dom";

export function Footer() {
  const getYearString = () => {
    const currentYear = new Date().getFullYear();

    return currentYear > 2022 ? `2022 - ${currentYear}` : "2022";
  };

  const currentVersion = import.meta.env.VITE_APP_VERSION;

  return (
    <div className="flex justify-between flex-none px-8 py-2 mb-2 sm:px-4 bg-slate-100">
      <p className="text-sm">
        {`\u00A9 ${getYearString()} `}
        <a href="https://cemm.at/" target="_blank" className="underline hover:no-underline">
          CeMM
        </a>{" "}
        (v1.0.4)
      </p>

      <p className="text-sm">
        <Link className="underline hover:no-underline" to="/disclaimer">
          Disclaimer
        </Link>
        <span>{"\u00A0\u00A0•\u00A0\u00A0"}</span>
        <Link className="underline hover:no-underline" to="/policy">
          Privacy Policy
        </Link>
      </p>
    </div>
  );
}
