import React, { SetStateAction } from "react";
import { classNames } from "../../../util/util";

import { ViewGridIcon, ArrowCircleLeftIcon } from "@heroicons/react/solid";

interface IFOVControlsProps {
  gridShown: boolean;
  setGridShown: React.Dispatch<SetStateAction<boolean>>;
  FOVIndex: number;
  onIncrement: (direction: -1 | 1) => void;
  imageCount: number;
}

export default function FOVControls({ gridShown, setGridShown, FOVIndex, onIncrement, imageCount }: IFOVControlsProps) {
  return (
    <>
      <div className="flex flex-row items-center justify-between w-full px-2 py-2 space-x-2 sm:space-x-0 bg-cemm-lightblue/25">
        <div className="flex-1 sm:flex-initial">
          <button
            onClick={() => setGridShown(true)}
            className={classNames(
              gridShown && "invisible",
              "flex items-center justify-center w-full py-[7px] px-2 sm:pl-1.5 sm:pr-3 font-medium leading-5 text-white uppercase transition-opacity md:w-auto bg-cemm-blue hover:bg-cemm-blue/90 font-heading text-sm"
            )}
          >
            <ViewGridIcon className="w-6 h-6 mr-2" />
            <span className="hidden sm:block">Show all fields of view</span>
            <span className="block sm:hidden">Show all</span>
          </button>
        </div>

        <div className={classNames(gridShown && "invisible", "flex space-x-2")}>
          <button
            type="button"
            title="Previous field of view"
            className={classNames(
              FOVIndex === 0 ? "opacity-50 cursor-not-allowed" : "bg-cemm-blue hover:bg-cemm-blue/90",
              "inline-flex items-center p-1.5 text-white bg-cemm-blue border border-transparent shadow-sm transition-opacity"
            )}
            onClick={() => onIncrement(-1)}
          >
            <ArrowCircleLeftIcon className="w-6 h-6" aria-hidden="true" />
          </button>
          <div className="flex items-center justify-center w-16 text-sm font-medium text-white font-heading text-mono bg-cemm-blue">
            <span>
              {FOVIndex + 1}/{imageCount}
            </span>
          </div>
          <button
            type="button"
            title="Next field of view"
            className={classNames(
              FOVIndex === imageCount - 1 ? "opacity-50 cursor-not-allowed" : "bg-cemm-blue hover:bg-cemm-blue/90",
              "inline-flex items-center p-1.5 text-white bg-cemm-blue border border-transparent shadow-sm transition-opacity"
            )}
            onClick={() => onIncrement(1)}
          >
            <ArrowCircleLeftIcon className="w-6 h-6 rotate-180" aria-hidden="true" />
          </button>
        </div>
      </div>
    </>
  );
}
