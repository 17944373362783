import { NavLink } from "react-router-dom";
import { classNames, toSlug } from "../util/util";

interface INavbarLinkProps {
  target: string;
}

export default function NavbarLink({ target }: INavbarLinkProps) {
  return (
    <NavLink
      to={`/${toSlug(target)}`}
      className={({ isActive }: { isActive: boolean }) =>
        classNames(
          isActive ? "bg-cemm-lightblue border-cemm-blue text-slate-900" : "hover:text-slate-900 text-slate-700",
          "inline-flex items-center py-4 px-8 sm:px-4 tracking-wide border-transparent hover:border-cemm-blue border-l-2 sm:border-l-0 sm:border-b-2"
        )
      }
    >
      {target}
    </NavLink>
  );
}
