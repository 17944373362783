import { Ref } from "react";
import type { ImageInfo, ImageObject } from "./types";

export function classNames(...classes: (string | boolean)[]) {
  return classes.filter(Boolean).join(" ");
}

export function toSlug(text: string) {
  return text.toLowerCase().replace(" ", "-");
}

const zeroPad = (num: number, places: number) => String(num).padStart(places, "0");

const storageURL = "https://introntaggingstorage.blob.core.windows.net/webtoolexports-v2";

export function constructImageObj(imageInfo: ImageInfo, image_ID: number) {
  let { experiment_ID, plate_ID, measurement_ID, row, column, FOV, frame } = imageInfo;

  const URI_generic = `/images/${experiment_ID}/plate${plate_ID}/measurement${measurement_ID}`;
  const URI_specific = `/r${zeroPad(row, 2)}c${zeroPad(column, 2)}f${zeroPad(FOV, 2)}p01_frame${frame}`;

  return {
    info: imageInfo,
    image_ID,
    URL: (thumb = true, channel = "011") =>
      `${storageURL}${thumb ? "/thumbnails" : ""}${URI_generic}${URI_specific}_${channel}${thumb ? "_thumb" : ""}.jpg`,
  } as ImageObject;
}

export function thumbnailToURL(description: string) {
  return `${storageURL}${description}`;
}

export function setScrollOffset(ref: Ref<HTMLDivElement>, index: number) {
  const widthThreshold = 500;
  const offsetLeft = 148;

  if (ref.current !== null && ref.current.clientWidth > widthThreshold) {
    ref.current.scrollLeft = offsetLeft * (index - (Math.floor(ref.current.clientWidth / offsetLeft) - 1) / 2);
  }
}

export function formatThousands(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
