import { useEffect } from "react";
import { useQuery } from "react-query";
import { useApi } from "../App";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";
import { Searchbox } from "../components/Searchbox";

import { thumbnailToURL, formatThousands } from "../util/util";

export default function Intro() {
  const api = useApi();

  useEffect(() => {
    document.title = "vpCells by CeMM";
  }, []);

  const { data: genes, isLoading: isLoadingGenes } = useQuery("genes", api.genes.getGenes, {
    select: (x) => x.data.genes,
  });

  const { data: stats, isLoading: isLoadingStats } = useQuery("stats", api.stats.getIntroStats, {
    select: (x) => x.data.stats,
  });

  const { data: featuredGenes, isLoading: isLoadingFeatured } = useQuery("featuredGenes", api.genes.getFeaturedGenes, {
    select: (x) => x.data.genes,
  });

  if (isLoadingGenes || genes === undefined) {
    return <Loading />;
  }

  if (isLoadingStats || stats === undefined) {
    return <Loading />;
  }

  if (isLoadingFeatured || featuredGenes === undefined) {
    return <Loading />;
  }

  return (
    <div className="px-8 py-12 my-4 sm:px-4 bg-slate-100">
      <div className="flex flex-col-reverse sm:divide-x sm:grid sm:grid-cols-3 divide-slate-300 sm:max-w-7xl sm:mx-auto">
        <div className="flex flex-col justify-center pt-8 font-medium border-t sm:pt-0 border-slate-300 sm:border-0 sm:pl-4 sm:pr-6 sm:space-y-8 font-heading">
          <div className="flex flex-row-reverse items-baseline justify-between sm:items-end sm:flex-col">
            <span className="text-3xl sm:text-5xl text-slate-700">{formatThousands(stats.geneCount)}</span>
            <div className="text-sm leading-6 uppercase sm:text-base text-slate-700">proteins</div>
          </div>
          <div className="flex flex-row-reverse items-baseline justify-between sm:items-end sm:flex-col">
            <span className="text-3xl sm:text-5xl text-slate-700">{formatThousands(stats.wellCount)}</span>
            <div className="text-sm leading-6 uppercase sm:text-base text-slate-700">clones</div>
          </div>
          <div className="flex flex-row-reverse items-baseline justify-between sm:items-end sm:flex-col">
            <span className="text-3xl sm:text-5xl text-slate-700">{formatThousands(stats.imageCount)}</span>
            <div className="text-sm leading-6 uppercase sm:text-base text-slate-700">images</div>
          </div>
        </div>
        <div className="col-span-2 pb-8 space-y-10 sm:pb-0 sm:pl-6 sm:pr-4">
          <div className="space-y-4">
            <p className="font-sans text-justify">
              Welcome to our collection of endogenously fluorescently labeled proteins in HAP1 and HEK293T cells.
            </p>
          </div>

          <div className="flex flex-col space-y-4 xl:space-y-0 xl:space-x-4 xl:flex-row">
            <div className="w-full mr-auto lg:w-2/3 xl:w-1/2">
              <Searchbox genes={genes} isOnMainPage />
            </div>
            <div className="items-center hidden font-medium uppercase xl:flex text-slate-700 font-heading">or</div>
            <div className="flex w-full mr-auto lg:w-2/3 xl:w-1/2">
              <Link
                to="/gallery"
                className="flex-1 block px-3 py-3 font-medium text-white uppercase transition-opacity bg-cemm-blue hover:bg-cemm-blue/90 font-heading"
              >
                Browse the gallery
              </Link>
            </div>
          </div>

          <div>
            <div className="mb-2 text-sm font-medium leading-6 text-left uppercase sm:text-base text-slate-700 font-heading">
              featured proteins
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6">
              {featuredGenes.map(({ gene }) => (
                <Link to={`/protein/${gene}`} className="px-2 pt-2 pb-2 text-left hover:bg-cemm-lightblue/25">
                  <img
                    src={thumbnailToURL(`/thumbnails/proteins/${gene}.jpg`)}
                    alt={`${gene} thumbnail image`}
                    className="w-full aspect-square"
                  />

                  <h3 className="mt-2 font-medium font-heading text-cemm-blue">{gene}</h3>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
