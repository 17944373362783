import { useState } from "react";
import { Tooltip, Accordion } from "@mantine/core";
import Inforow from "./Inforow";

import { ClipboardCheckIcon } from "@heroicons/react/solid";

interface IGuideInfoboxProps {
  data: {
    ENSEMBL_transcript_ID: string;
    sgRNA_ID: string;
    frame: number;
    intron: number;
    chromosome: string;
    start: string;
    end: string;
    strand: string;
    sequence: string;
  };
}

export default function GuideInfobox({ data }: IGuideInfoboxProps) {
  const [accordionValue, setAccordionValue] = useState<string | null>(null);
  const [copied, setCopied] = useState(false);

  const { ENSEMBL_transcript_ID, sgRNA_ID, frame, intron, chromosome, start, end, strand, sequence } = data;

  function copyGuide() {
    navigator.clipboard.writeText(sgRNA_ID);

    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  }

  return (
    <dl className="flex flex-col mb-6 gap-y-1">
      <Accordion
        onChange={setAccordionValue}
        classNames={{
          item: "border-0",
          control: "p-0 mb-1 mt-1 flex flex-row-reverse justify-between w-full hover:bg-transparent",
          label: "text-slate-700 font-medium font-sans leading-6",
          chevron: accordionValue
            ? "m-0 text-slate-900 block w-6 rotate-180 transition-transform"
            : "m-0 text-slate-900 block w-6 transition-transform",
          content: "p-0 font-sans font-medium leading-6 flex flex-col gap-y-1",
        }}
      >
        <Accordion.Item value="details">
          <Accordion.Control>{accordionValue ? "Hide sgRNA details" : "Show sgRNA details"}</Accordion.Control>

          <Accordion.Panel>
            <Inforow label={"ENSEMBL transcript ID"} content={ENSEMBL_transcript_ID} />
            <Inforow label={"Intron"} content={intron} />
            <Inforow label={"Chromosome"} content={chromosome} />
            <Inforow label={"Start"} content={start} />
            <Inforow label={"End"} content={end} />
            <Inforow label={"Strand"} content={strand} />
            <Inforow
              label={"sgRNA ID"}
              content={
                <div className="flex items-center cursor-pointer group">
                  <span onClick={copyGuide} className="underline group-hover:no-underline underline-offset-1">
                    Copy to clipboard
                  </span>

                  {copied && <ClipboardCheckIcon className="w-4 h-4 ml-1 text-slate-900" />}
                </div>
              }
            />
            <Inforow label={"sgRNA sequence"} content={sequence} />
            <Inforow label={"Frame"} content={frame} />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </dl>
  );
}
