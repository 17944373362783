import { useRef, useState, useMemo, useEffect } from "react";
import { useQuery } from "react-query";
import { useApi } from "../../App";
import { useParams, Navigate } from "react-router-dom";
import { setScrollOffset } from "../../util/util";
import { getSgRNAs, getWellObjects } from "../../util/proteinData";

import Loading from "../../components/Loading";
import GuideOverview from "./GuideOverview";
import GuideScrollbar from "./Scrollbars/Guide";
import WellScrollbar from "./Scrollbars/Well";
import ImageDetail from "./ImageDetail";
import Panel from "./Infoboxes/Panel";

export default function Protein() {
  const api = useApi();
  const { proteinId } = useParams();
  const sgRNARef = useRef<HTMLDivElement>(null);
  const wellRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    console.log(`set ${proteinId} as title`);
    document.title = `${proteinId} | vpCells`;
  }, []);

  const [selectedWellID, setSelectedWellID] = useState<number>(); // use well ID
  const [selectedImageID, setSelectedImageID] = useState<number>(); // use image ID
  const [gridShown, setGridShown] = useState(false);

  const [sgRNAIndex, setSgRNAIndex] = useState(0);
  const [wellIndex, setWellIndex] = useState(0);
  const [FOVIndex, setFOVIndex] = useState(0);

  if (proteinId === undefined) {
    return <Navigate replace to="/gallery" />;
  }

  const { data: gene, isLoading: isLoadingGene } = useQuery(
    ["gene", proteinId],
    () => api.genes.getGeneDetail(proteinId),
    {
      select: (x) => x.data.gene,
      onSuccess: (data) => {
        if (data) {
          api.stats.recordGeneView({ gene_ID: data.ENSEMBL_gene_ID });
        }
      },
    }
  );

  const sgRNAs = useMemo(() => {
    if (gene) {
      return getSgRNAs(gene);
    } else {
      return [];
    }
  }, [gene]);

  const { data: wells, isLoading: isLoadingWells } = useQuery(
    ["wells", proteinId, sgRNAIndex],
    () => api.genes.getWells(proteinId, sgRNAs[sgRNAIndex].sgRNA_ID),
    { select: (x) => x.data.wells, enabled: sgRNAs.length > 0 }
  );

  const wellObjects = useMemo(() => getWellObjects(wells), [wells]);
  const wellDetail = useMemo(
    () => wellObjects.find((well) => well.well_ID === selectedWellID),
    [wellObjects, selectedWellID]
  );

  function handleSgRNASelect(sgRNAIndex: number) {
    setSgRNAIndex(sgRNAIndex);
    setWellIndex(0);
    setScrollOffset(sgRNARef, sgRNAIndex);
  }

  function handleWellSelect(well_ID: number) {
    setSelectedWellID(well_ID);
    setWellIndex(wellObjects.findIndex((a) => a.well_ID === well_ID));
    setGridShown(false);
    // setScrollOffset(wellRef, wellIndex);
  }

  function handleFOVSelect(image_ID: number, index: number) {
    setSelectedImageID(image_ID);
    setFOVIndex(index);
  }

  function incrementFOV(direction: -1 | 1) {
    const newIndex =
      direction < 0 ? Math.max(0, FOVIndex - 1) : Math.min((wellDetail?.FOVs?.length || 1) - 1, FOVIndex + 1);

    setFOVIndex(newIndex);
    setSelectedImageID(wellDetail?.FOVs[newIndex].image_ID);
  }

  useEffect(() => {
    if (wellObjects.length) {
      setSelectedWellID(wellObjects[0].well_ID);
      setSelectedImageID(wellDetail?.FOVs[0].image_ID);
      setFOVIndex(0);
    }
  }, [wellObjects]);

  useEffect(() => {
    setSelectedImageID(wellDetail?.FOVs[0].image_ID);
    setFOVIndex(0);
  }, [selectedWellID]);

  useEffect(() => handleSgRNASelect(0), [proteinId]);

  if (gene === null) {
    return <Navigate replace to="/gallery" />;
  }

  if (isLoadingGene || gene === undefined) {
    return <Loading />;
  }

  return (
    <div className="px-8 py-6 my-4 sm:px-4 bg-slate-100">
      <div className="grid w-full grid-cols-1 lg:grid-cols-5 xl:grid-cols-4 sm:divide-x divide-slate-200">
        {/* left sidebar */}
        <div className="flex-col hidden mb-6 text-left xl:flex sm:mt-0 sm:px-6">
          <GuideOverview sgRNAs={sgRNAs} onSelect={handleSgRNASelect} guideIndex={sgRNAIndex} />
        </div>

        {/* centre panel */}
        <div className="flex flex-col lg:col-span-3 xl:col-span-2 sm:px-6">
          <GuideScrollbar sgRNAs={sgRNAs} onSelect={handleSgRNASelect} guideIndex={sgRNAIndex} reference={sgRNARef} />
          <WellScrollbar
            wellObjects={wellObjects}
            onSelect={handleWellSelect}
            selectedWell={selectedWellID}
            wellIndex={wellIndex}
            reference={wellRef}
            isLoading={isLoadingWells}
          />
          <ImageDetail
            FOVs={wellDetail?.FOVs}
            FOVIndex={FOVIndex}
            onSelect={handleFOVSelect}
            onIncrement={incrementFOV}
            gridShown={gridShown}
            setGridShown={setGridShown}
          />
        </div>

        {/* right sidebar */}
        <div className="flex flex-col mt-6 mb-6 text-left lg:col-span-2 xl:col-span-1 md:mt-0 sm:px-6">
          <Panel
            geneID={gene.gene}
            geneName={gene.description}
            sgRNAs={sgRNAs}
            sgRNAIndex={sgRNAIndex}
            wellDetail={wellDetail}
            FOVIndex={FOVIndex}
          />
        </div>
      </div>
    </div>
  );
}
