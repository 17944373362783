import React, { useState, useEffect, SetStateAction } from "react";
import { Switch } from "@headlessui/react";

import { classNames } from "../../../util/util";

interface IChannelControlsProps {
  setChannels: React.Dispatch<SetStateAction<string>>;
}

export default function ChannelControls({ setChannels }: IChannelControlsProps) {
  const [red, setRed] = useState(false);
  const [green, setGreen] = useState(true);
  const [blue, setBlue] = useState(true);

  useEffect(() => setChannels(`${+red}${+green}${+blue}`), [red, green, blue]);

  return (
    <>
      <div className="flex flex-col items-center justify-around w-full py-4 gap-y-4 sm:gap-0 sm:flex-row bg-cemm-lightblue/25">
        <Switch.Group
          as="div"
          className="flex flex-row-reverse items-center justify-between w-full px-4 space-x-2 sm:flex-row sm:px-0 sm:justify-center "
        >
          <Switch
            disabled={!red && !blue}
            checked={green}
            onChange={setGreen}
            className={classNames(
              green ? "bg-green-500" : "bg-cemm-blue/20",
              !red && !blue && "cursor-not-allowed",
              "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent cursor-pointer transition-colors ease-in-out duration-200"
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                green ? "translate-x-5" : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 bg-white shadow transform transition ease-in-out duration-200"
              )}
            />
          </Switch>
          <Switch.Label as="div" className="font-semibold text-slate-900">
            target protein
          </Switch.Label>
        </Switch.Group>
        <Switch.Group
          as="div"
          className="flex flex-row-reverse items-center justify-between w-full px-4 space-x-2 sm:flex-row sm:px-0 sm:justify-center"
        >
          <Switch
            disabled={!green && !red}
            checked={blue}
            onChange={setBlue}
            className={classNames(
              blue ? "bg-blue-600" : "bg-cemm-blue/20",
              !green && !red && "cursor-not-allowed",
              "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent cursor-pointer transition-colors ease-in-out duration-200"
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                blue ? "translate-x-5" : "translate-x-0",

                "pointer-events-none inline-block h-5 w-5 bg-white shadow transform transition ease-in-out duration-200"
              )}
            />
          </Switch>
          <Switch.Label as="div" className="font-semibold text-slate-900">
            nuclei
          </Switch.Label>
        </Switch.Group>
        <Switch.Group
          as="div"
          className="flex flex-row-reverse items-center justify-between w-full px-4 space-x-2 sm:flex-row sm:px-0 sm:justify-center"
        >
          <Switch
            disabled={!green && !blue}
            checked={red}
            onChange={setRed}
            className={classNames(
              red ? "bg-red-600" : "bg-cemm-blue/20",
              !green && !blue && "cursor-not-allowed",
              "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent cursor-pointer transition-colors ease-in-out duration-200"
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                red ? "translate-x-5" : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 bg-white shadow transform transition ease-in-out duration-200"
              )}
            />
          </Switch>
          <Switch.Label as="div" className="font-semibold text-slate-900">
            membranes
          </Switch.Label>
        </Switch.Group>
      </div>
    </>
  );
}
