import Inforow from "./Inforow";

import { ExternalLinkIcon } from "@heroicons/react/solid";

interface IGeneInfoboxProps {
  data: {
    ENSEMBL_gene_ID: string;
    gene_ID: string;
  };
}

export default function GeneInfobox({ data: { ENSEMBL_gene_ID, gene_ID } }: IGeneInfoboxProps) {
  return (
    <dl className="flex flex-col mt-3 sm:mt-6 gap-y-1">
      <Inforow
        label={"ENSEMBL gene ID"}
        content={
          <a
            target="_blank"
            className="flex items-center group"
            href={`https://www.ensembl.org/Homo_sapiens/Gene/Summary?g=${ENSEMBL_gene_ID}`}
          >
            <span className="underline group-hover:no-underline underline-offset-1">{ENSEMBL_gene_ID}</span>

            <ExternalLinkIcon className="w-4 h-4 ml-1 text-slate-900" />
          </a>
        }
      />

      <Inforow
        label={"Human Protein Atlas"}
        content={
          <a
            target="_blank"
            className="flex items-center group"
            href={`https://www.proteinatlas.org/${ENSEMBL_gene_ID}-${gene_ID}/subcellular`}
          >
            <span className="underline group-hover:no-underline underline-offset-1">Link</span>

            <ExternalLinkIcon className="w-4 h-4 ml-1 text-slate-900" />
          </a>
        }
      />

      <Inforow
        label={"CZ Biohub OpenCell"}
        content={
          <a
            target="_blank"
            className="flex items-center group"
            href={`https://opencell.czbiohub.org/search/${gene_ID}`}
          >
            <span className="underline group-hover:no-underline underline-offset-1">Link</span>

            <ExternalLinkIcon className="w-4 h-4 ml-1 text-slate-900" />
          </a>
        }
      />
    </dl>
  );
}
