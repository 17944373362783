import { useEffect } from "react";
import { Tooltip } from "@mantine/core";

const teamMembers = [
  {
    name: "Jiří Reiniš",
    slug: "jreinis",
    contribution: "coordination, data acquisition and management",
  },
  {
    name: "Pavel Růžička",
    slug: "pruzicka",
    contribution: "website design and engineering",
  },
  {
    name: "Andreas Reicher",
    slug: "areicher",
    contribution: "wet lab, intron tagging technology",
  },
  {
    name: "Maria Ciobanu",
    slug: "mciobanu",
    contribution: "wet lab, logistics, frozen clones",
  },
  {
    name: "Stefan Kubicek",
    slug: "skubicek",
    contribution: "vision",
  },
];

export default function Contact() {
  useEffect(() => {
    document.title = "Contact | vpCells";
  }, []);

  return (
    <div className="px-8 py-12 my-4 sm:px-4 bg-slate-100">
      <div className="w-full px-4 lg:w-2/3 lg:mx-auto">
        <h1 className="mb-6 text-3xl font-bold leading-6 text-left uppercase tracking-tightest text-slate-700 font-heading">
          Contact us
        </h1>
        <section className="text-left border-t border-slate-300 text-slate-900">
          <p className="mt-8 text-justify">
            Please address your questions and feedback to{" "}
            <a
              href={"mailto:vpcells" + "\u0040" + "cemm.at"}
              target="_blank"
              className="underline underline-offset-4 hover:no-underline"
            >
              vpcells{"\u0040"}cemm.at
            </a>
            .
          </p>
          <h2 className="mt-8 text-xl uppercase font-heading tracking-tightested text-slate-700">The team</h2>

          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5">
            {teamMembers.map(({ name, slug, contribution }) => (
              <Tooltip
                position="top"
                label={`Email ${name.split(" ")[0]}`}
                withArrow
                allowPointerEvents
                classNames={{ body: "font-sans text-base font-semibold rounded-none bg-slate-700" }}
              >
                <a
                  href={`mailto:${slug}${"\u0040"}cemm.oeaw.ac.at`}
                  target="_blank"
                  className="block px-2 pt-2 pb-2 mt-4 text-left hover:bg-cemm-lightblue/25"
                >
                  <img src={`/team/${slug}.jpg`} alt={`${name} image`} className="w-full aspect-square" />

                  <h3 className="mt-2 font-bold uppercase font-heading text-cemm-blue">{name}</h3>
                  <p className="font-sans text-sm text-slate-900">{contribution}</p>
                </a>
              </Tooltip>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
}
