import React from "react";

interface IInforowProps {
  label: string;
  content: React.ReactNode | string | number;
}

export default function Row({ label, content }: IInforowProps) {
  return (
    <div className="flex flex-wrap justify-between">
      <dt className="font-medium text-slate-500">{label}</dt>
      <dd className="ml-auto font-semibold text-slate-900">{content}</dd>
    </div>
  );
}
