import React from "react";
import { render } from "react-dom";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./index.css";

import { App } from "./App";
import Intro from "./views/Intro";
import Gallery from "./views/Gallery/Main";
import Protein from "./views/Protein/Main";
import About from "./views/About";
import Downloads from "./views/Downloads";
import Contact from "./views/Contact";
import PrivacyPolicy from "./views/PrivacyPolicy";
import Disclaimer from "./views/Disclaimer";

const container = document.getElementById("app");
const root = createRoot(container!);

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<Intro />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="protein" element={<Navigate replace to="/gallery" />} />
        <Route path="protein/:proteinId" element={<Protein />} />
        <Route path="about" element={<About />} />
        <Route path="downloads" element={<Downloads />} />
        <Route path="contact" element={<Contact />} />
        <Route path="policy" element={<PrivacyPolicy />} />
        <Route path="disclaimer" element={<Disclaimer />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Route>
    </Routes>
  </BrowserRouter>
);
